/* eslint-disable camelcase */
const getLoggedInUserAnalyticsData = () => ({
  hash: localStorage.getItem('hash'),
  email: localStorage.getItem('user_email'),
  user_id: localStorage.getItem('user_id'),
  streamId: localStorage.getItem('streamId'),
  platformType: localStorage.getItem('platformType')
});

export const googleAnalyticsPageView = (url) => {
  pushDataLayerForEvent('js', { page_path: url, config: process.env.NEXT_PUBLIC_NEW_GOOGLE_TAG_MANAGER_ID });
};

export const onZoomMeetingLinkEntered = () => {
  pushDataLayerForEvent('enter_zoom_meeting_link');
};

export const onZoomBotAdmitted = () => {
  pushDataLayerForEvent('zoom_bot_admitted');
};

export const onZoomMeetingTitleEntered = () => {
  pushDataLayerForEvent('enter_zoom_meeting_title');
};

export const onZoomMeetingLinkAdd = () => {
  pushDataLayerForEvent('enter_zoom_meeting_link_add');
};

export const onZoomMeetingTimeEntered = () => {
  pushDataLayerForEvent('enter_zoom_meeting_time');
};

const internalDomains = ['@streamalive'];
const isInternalUser = (email) => {
  const emailDomain = email.substring(email.indexOf('@'));
  return internalDomains.some((domain) => emailDomain.startsWith(domain));
};

export const pushDataLayerForEvent = (eventName, parameters, loggedIn = true) => {
  const eventParams = parameters || {};

  if (loggedIn) {
    const {
      hash,
      email,
      user_id,
      streamId,
      platformType
    } = getLoggedInUserAnalyticsData();

    eventParams.hash = hash;
    eventParams.email = email;
    eventParams.user_id = user_id;
    eventParams.streamId = streamId;
    eventParams.platformType = platformType;
  }

  if (eventParams && eventParams.email && isInternalUser(eventParams.email)) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  function gtag(args) {
    window.dataLayer.push(args);
  }

  if (process.env.NEXT_PUBLIC_NEW_GOOGLE_TAG_MANAGER_ID !== 'GTM-dummy') {
    gtag({ event: eventName, ...eventParams });
  }
};
